import usePageProps from 'modules/common/hooks/usePageProps';
import { MdxPageProps } from '../server';

const useMdx = () => {
  const { pageProps } = usePageProps();

  return (pageProps ? pageProps?.mdx || {} : {}) as { [key: string]: MdxPageProps };
};

export default useMdx;
