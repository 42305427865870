import 'dayjs/locale/fr';

import React from 'react';
import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import relativeTime from 'dayjs/plugin/relativeTime';
import localizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.extend(relativeTime);
dayjs.extend(localeData);
dayjs.extend(localizedFormat);
dayjs().localeData();

import { useRouter } from 'next/router';

function useLocale(passedLocale?: string) {
  const { locale: routerLocale, defaultLocale } = useRouter();

  const locale = passedLocale || routerLocale;

  React.useEffect(persistLocaleCookie, [locale, defaultLocale]);

  function persistLocaleCookie() {
    dayjs.locale(locale);
    const date = new Date();
    const expireMs = 100 * 24 * 60 * 60 * 1000; // 100 days
    date.setTime(date.getTime() + expireMs);
    document.cookie = `NEXT_LOCALE=${locale};expires=${date.toUTCString()};path=/`;
  }
}

export default useLocale;
