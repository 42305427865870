import { create } from 'zustand';
import { combine } from 'zustand/middleware';
import type { AppProps } from 'next/app';
import isEqual from 'lodash/fp/isEqual';

const usePageProps = create(
  combine({ pageProps: undefined as AppProps['pageProps'] }, (set) => ({
    setPageProps: (pageProps: AppProps['pageProps']) => {
      return set((state) => {
        return isEqual(pageProps)(state.pageProps) ? state : { ...state, pageProps };
      });
    },
  }))
);

export default usePageProps;
