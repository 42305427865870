import { Ubuntu, Red_Hat_Display, Inter } from 'next/font/google';

const primary = Inter({
  weight: ['100', '200', '300', '400', '500', '600', '700', '800', '900'],
  subsets: ['latin'],
  variable: '--font-primary',
  display: 'swap',
});

const secondary = Red_Hat_Display({
  subsets: ['latin'],
  variable: '--font-secondary',
  display: 'swap',
});

const logo = Ubuntu({
  weight: ['300', '400', '500', '700'],
  subsets: ['latin'],
  variable: '--font-logo',
  display: 'swap',
});

const className = `${primary.variable} ${secondary.variable} ${logo.variable} ${primary.className} antialiased`;

export default className;
