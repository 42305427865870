'use client';

// import { useRouter } from 'next/router';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

const usePublicRuntimeConfig = () => {
  // const { locale } = useRouter();
  return {
    // telegram: locale === 'fr' ? 'https://t.me/+7lU4ZIq2xnZhZTlk' : 'https://t.me/+eow6sBBLR-VkNWRk',
    ...publicRuntimeConfig,
  };
};

export default usePublicRuntimeConfig;
