import { ApolloProvider } from '@apollo/client';
import type { AppProps } from 'next/app';
import useApollo from '../hooks/useApollo';

const GraphQLProvider = ({ pageProps, children }: AppProps['pageProps']) => {
  const apolloClient = useApollo(pageProps);

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};

export default GraphQLProvider;
