import React from 'react';
import { MDXRemote } from 'next-mdx-remote';
import Link from './Link';
import Image from 'next/image';
import type { MdxPageProps } from '../hoc/withMdx';

type MdxProps = {
  imageSizes?: MdxPageProps['imageSizes'];
} & React.ComponentProps<typeof MDXRemote>;

const Mdx: React.FC<MdxProps> = ({ imageSizes = {}, components, ...props }) => {
  return (
    <MDXRemote
      components={{
        Link,
        img: (props: any) => {
          if (imageSizes[props.src]) {
            const { src, alt } = props;
            const { width, height } = imageSizes[props.src];
            return <Image src={src} alt={alt} width={width} height={height} />;
          } else {
            // If we don’t have the image’s dimensions, let’s use a classic
            // `img` element.
            return <img {...props} />;
          }
        },
        a: ({ href, children }: any) => {
          if (href.startsWith('/')) {
            return <Link href={href}>{children}</Link>;
          }
          return (
            <a href={href} target="_blank" rel="noreferrer">
              {children}
            </a>
          );
        },
        ...components,
      }}
      {...props}
    />
  );
};

export default Mdx;
