import React from 'react';
import useUrl from 'modules/common/hooks/useUrl';
import { useAuthModal } from 'modules/auth';
import { useCookie, useToggle } from 'react-use';
import dayjs from 'dayjs';
import useFingerprint from 'modules/common/hooks/useFingerprint';
import { post } from 'utils/api';
import getConfig from 'next/config';
const { publicRuntimeConfig } = getConfig();

const useAffiliation = () => {
  const { queryParams, removeQueryParam } = useUrl();
  const [referralCode, setReferralCode] = useCookie(publicRuntimeConfig.affiliateCookieName);
  const [sendStat, toggleSendStat] = useToggle(false);
  const [sentStat, toggleSentStat] = useToggle(false);

  const fingerprint = useFingerprint();

  const { session } = useAuthModal();

  React.useEffect(() => {
    if (queryParams[publicRuntimeConfig.affiliateQueryParam] && !session?.user) {
      if (!referralCode) {
        setReferralCode(queryParams?.affiliateId, { expires: dayjs().add(10, 'years').toDate() });
      }
      toggleSendStat(true);

      removeQueryParam(publicRuntimeConfig.affiliateQueryParam);
    }
  }, [queryParams, toggleSendStat, session?.user, removeQueryParam, setReferralCode, referralCode]);

  React.useEffect(() => {
    if (fingerprint && !sentStat && sendStat) {
      toggleSentStat(true);
      post('/api/affiliation/analytics', { event: 'visit' });
    }
  }, [fingerprint, sendStat, sentStat, toggleSentStat]);
};

export default useAffiliation;
