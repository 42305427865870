import React from 'react';
import classNames from 'classnames';
import { MdAddTask } from 'react-icons/md';
export type EmptyStateProps = {
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  cta?: string;
  icon?: any;
  iconClassName?: any;
  onClick?: any;
} & React.HTMLAttributes<HTMLDivElement>;

const EmptyState: React.FC<EmptyStateProps> = ({
  children,
  onClick,
  title,
  icon: Icon = MdAddTask,
  iconClassName,
  subtitle,
  className,
  cta,
  ...props
}) => {
  return (
    <section className={classNames('empty-simple', className)} {...props}>
      {Icon && <Icon aria-hidden="true" className={iconClassName} />}
      {title && <h3>{title}</h3>}
      {subtitle && <p>{subtitle}</p>}
      {onClick && (
        <button type="button" onClick={onClick}>
          {Icon && <Icon aria-hidden="true" />}
          {cta}
        </button>
      )}
    </section>
  );
};

export default EmptyState;
