import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment } from 'react';
import { MdClose } from 'react-icons/md';

import { useKeyPressEvent } from 'react-use';
import ErrorBoundary from './ErrorBoundary';

export interface ModalProps {
  children?: React.ReactNode;
  className?: string;
  bgClassName?: string;
  title?: string;
  open: boolean;
  onClose?: (event: any) => any;
  render?: (data: any) => any;
  showHeader?: boolean;
}

const Modal: React.FC<ModalProps> = ({
  title,
  children,
  className = 'max-w-2xl',
  bgClassName = 'bgd-white shadow-lg',
  onClose,
  open,
  showHeader = true,
}) => {
  const getTopOffset = () => {
    if (typeof window === 'undefined') {
      return 0;
    }
    const scrollY = window.scrollY;
    // Adjust this value as needed to position the modal
    const topOffset = 20; // 20px from the top of the viewport
    return scrollY + topOffset;
  };

  // State to store the top offset
  const [topOffset, setTopOffset] = React.useState(getTopOffset());

  useKeyPressEvent('Escape', () => onClose?.(false));

  // Update the top offset when the modal is opened or the window is scrolled
  React.useEffect(() => {
    if (open) {
      setTopOffset(getTopOffset());
    }

    const handleScroll = () => {
      if (open) {
        setTopOffset(getTopOffset());
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [open]);

  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog as="div" className="px-4 pb-20 pt-4 text-center sm:block sm:p-0" onClose={onClose as any}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 z-20 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Dialog.Description
            as="div"
            data-cy="modal"
            className={`absolute inset-x-6 top-6 z-40 mx-auto flex flex-col border border-black shadow-xl ${bgClassName} ${className}`}
            style={{ top: `${topOffset}px`, maxHeight: `calc(100dvh - 2 * 1.5rem)` }}
          >
            {showHeader && (
              <div className={`flex items-center justify-between px-4 py-2 ${title ? 'mb-5 border-b' : ''}`}>
                <h3 className="text-lg font-semibold">{title}</h3>
                <button className="textd-black" onClick={onClose} aria-label="Close">
                  <MdClose className="size-6" />
                </button>
              </div>
            )}
            <div className="mx-2 flex h-full flex-col items-center overflow-y-auto">
              <ErrorBoundary>{children}</ErrorBoundary>
            </div>
            <button />
          </Dialog.Description>
        </Transition.Child>
      </Dialog>
    </Transition>
  );
};

export default Modal;
