import { useLocalStorageValue } from '@react-hookz/web';

export type Theme = 'dark' | 'light';
export type SetTheme = (val: Theme) => void;

const useTheme = () => {
  const { value, set } = useLocalStorageValue<Theme>('theme', { defaultValue: 'light', initializeWithValue: false });

  const setValue: SetTheme = (val) => {
    set(val);
  };

  return [value, setValue] as [Theme, SetTheme];
};

export default useTheme;
