'use client';
import 'react-toastify/dist/ReactToastify.css';
import React from 'react';
import { ToastContainer, ToastOptions, ToastContent, toast } from 'react-toastify';
import useUrl from './useUrl';

const defaultOptions: Partial<ToastOptions> = {
  position: 'top-center',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
};

const useNotifier = () => {
  const notify = (variant: ToastOptions['type'] = 'success', message: ToastContent, options: ToastOptions = {}) => {
    if (variant === 'error' && !options.autoClose) {
      options.autoClose = 8000;
    }

    return toast(message, { ...defaultOptions, type: variant, ...options });
  };

  return { notify };
};

export const NotifierContainer = (props: any) => {
  const { queryParams, removeQueryParam } = useUrl();

  React.useEffect(() => {
    if (queryParams.notify) {
      const [variant, message] = queryParams.notify.split(':');

      toast(message, { ...defaultOptions, type: variant });
      removeQueryParam('notify', undefined, { shallow: true });
    }
  }, [queryParams.notify, removeQueryParam]);

  return <ToastContainer {...props} />;
};
export default useNotifier;
