import React from 'react';
import { useToggle } from '@react-hookz/web';
import copy from 'copy-to-clipboard';
import { MdContentCopy as ClipboardIcon, MdCheck as CheckIcon } from 'react-icons/md';
import classNames from 'classnames';
type CopyButtonProps = {
  value?: string;
  timeout?: number;
  icon?: any;
  iconPosition?: 'before' | 'after';
} & React.HTMLAttributes<HTMLButtonElement>;

const CopyButton = ({
  value,
  timeout = 1000,
  children,
  className,
  title,
  iconPosition = 'after',
  icon: Icon = ClipboardIcon,
  onClick,
  ...props
}: CopyButtonProps) => {
  const [copied, toggleCopied] = useToggle(false);
  const copyUrl = (e: any) => {
    toggleCopied();
    copy(value || '');
    if (onClick) {
      onClick(e);
    }
    setTimeout(toggleCopied, timeout);
  };

  const displayedIcon = copied ? <CheckIcon className="size-5" /> : <Icon className="size-5" />;

  return (
    <button type="button" onClick={copyUrl} className={classNames('inline-flex items-center gap-2', className)} title={value} {...props}>
      {iconPosition === 'before' && displayedIcon}
      {children && <span>{children}</span>}
      {iconPosition === 'after' && displayedIcon}
    </button>
  );
};

export default CopyButton;
