import React from 'react';
import useTheme, { type Theme } from '../hooks/useTheme';

const ThemeSwitcher = () => {
  const [value, setValue] = useTheme();

  const changeTheme = React.useCallback(
    (theme: Theme) => {
      const html = document.getElementsByTagName('html')[0];

      if (theme === 'light') {
        html.classList.remove('dark');
        html.classList.add('light');
        html.setAttribute('data-theme', 'light');
        setValue('light');
      } else {
        html.classList.remove('light');
        html.classList.add('dark');
        html.setAttribute('data-theme', 'dark');
        setValue('dark');
      }
    },
    [value]
  );

  React.useEffect(() => {
    const html = document.getElementsByTagName('html')[0];
    if (value && !Array.prototype.slice.call(html.classList).includes(value)) {
      changeTheme(value);
    }
  }, []);

  return <button onClick={() => changeTheme(value === 'dark' ? 'light' : 'dark')}>{value}</button>;
};

export default ThemeSwitcher;
