// https://github.com/michelalbers/use-fingerprint/blob/master/src/useFingerprint.ts
import React from 'react';
import Fingerprint2 from 'fingerprintjs2';
import { useCookie } from 'react-use';

function useFingerprint(options?: Fingerprint2.Options) {
  const [fingerprint, setFingerprint] = useCookie('fingerprint');

  React.useEffect(() => {
    if (typeof window !== 'object') return; // Run on the server side - ignore

    const setFingerprintFromComponents = () => {
      const callback = (components: any) => {
        const newFingerprint = Fingerprint2.x64hash128(components.map((c: any) => c.value).join(''), 31);
        setFingerprint(newFingerprint);
      };

      const args = [options, callback].filter((arg) => typeof arg !== 'undefined');

      // @ts-ignore
      Fingerprint2.get.apply(this, args);
    };

    // @ts-ignore
    if (window.requestIdleCallback) {
      // @ts-ignore
      window.requestIdleCallback(setFingerprintFromComponents);
    } else {
      setTimeout(setFingerprintFromComponents, 500);
    }
  }, []);

  return fingerprint;
}

export default useFingerprint;
