import axios, { AxiosRequestConfig } from 'axios';

// import { setupCache } from 'axios-cache-adapter';

const config: AxiosRequestConfig = { baseURL: process.env.NEXT_PUBLIC_BASE_PATH || '' };

// const cache = setupCache({
//   readHeaders: true, // this is used to cache data based on max-age headers
// });

const axiosInstance = axios.create({
  ...config,
  // adapter: cache.adapter,
});

export const fetcher = async (resource: any, init: any) =>
  axiosInstance
    .get(resource, init)
    .then((res) => res.data)
    .catch((error) => {
      console.error(error); //eslint-disable-line
      throw error;
    });

export const post = async <T>(uri: any, values: any) => {
  try {
    const { data } = await axiosInstance.post(uri, values);

    return data as T;
  } catch (error) {
    console.error(error); //eslint-disable-line
    throw error;
  }
};

export const getUrlType = async (url: string) => ((await axiosInstance.head(url))?.headers || {})['content-type'];

export default axiosInstance;
